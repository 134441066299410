import React from 'react';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import store from './redux/store';
import {PU} from './PopUpJK';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PU>
      <Provider store={store}>
        <App/>
      </Provider>
    </PU> 
  </React.StrictMode>,
);
