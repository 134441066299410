

const noteColor = {
    "red":'#de205377', 
    'orange':'#f57e2977',
    'yellow':'#efd93577',
    "green":'#65fa0077', 
    "blue":'#0635f177', 
    'indigo':'#1a065a77',
    'violet':'#78146a77',
};

export default noteColor;