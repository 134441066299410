import './Pages.css';
import {T} from '../PopUpJK';
import { GoMoveToEnd } from "react-icons/go";
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";
import { GoMoveToStart } from "react-icons/go";
import "../App.css";

function Pages({numPages, page, setPage}) {
  const pageBarStyle = {
    display:'flex', 
    justifyContent:'space-between', 
    backgroundColor:'rgb(34, 141, 177)',
    marginBottom: '20px',
  };
  return (
   <>
      <div className='note-item' style={pageBarStyle}>
        <span style={{fontSize:'12px', marginTop:'3px', float:'left'}}> Notes (React-Redux)</span>
        <div style={{display:'flex', justifyContent:'center'}}>
        {
          (numPages >= 2) &&
          <>
            {
              (page > 1) &&
              <>
                &nbsp;
                <button className='page-button' onClick={()=>setPage(0)}><T r={<GoMoveToStart />} s={'First page'} /> </button>
              </>
            }
            {
              (page > 0) &&
              <>
               &nbsp;
             <button className='page-button' onClick={()=>setPage(page=>page-1)}> <T r={<GrLinkPrevious />} s={'Prev page'} /></button>
              </>
            }
            &nbsp;
            <div className='currentPage'><T r={page + 1} s={'Current page'} /> </div>
            {
              (page < numPages - 1) &&
              <>
               &nbsp;
               <button className='page-button' onClick={()=>setPage(page=>page+1)}><T r={<GrLinkNext />} s={'Next page'} /></button>
              </>
            }
            {
              (page < numPages - 2) &&
              <>
              &nbsp;
              <button className='page-button' onClick={()=>setPage(numPages-1)}><T r={<GoMoveToEnd />} s={'Last page'} /></button>
              </>
            }         
          </>
        } 
        &nbsp;
        <div className='pageCount'><T r={numPages} s={'Page count'} /></div>
        </div>
      </div>
    </>
  );
}
export default Pages;