const aboutText = 
<>
This is a note taking application using React-Redux capability. <br />
1. You can make a note using Textarea. And for the background color <br />
   of the note, press a color button.<br />
2. 5 notes are shown as one page and page control capability is provided for multiple pages.<br />
3. If the content is so short to fit in one line, the whole content is shown in one line.<br />
4. But the content overflows one line, you should press the Expand button to see the whole.<br />
4. To test this app, press the Test data button. 6 notes will be shown. You can add <br />
  your own notes to check various functionality.<br />
5. Search capability is provided to find notes containing the search words.<br />
</>;

export default aboutText;