import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { delNote,addNote, setEditId } from "../redux/notes/notesSlice";
import "../App.css";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { IoExpand } from "react-icons/io5";
import { LuShrink } from "react-icons/lu";
import { MdEditOff } from "react-icons/md";
import { RiDeleteBin2Fill } from "react-icons/ri";
import {T} from '../PopUpJK';
import noteColor from './Colors';
import Pages from './Pages';
import {PUC} from '../PopUpJK';

const pageSize = 5;
const noteLineLen = 60;

const NoteItem = ({ items, focus }) => {
  const [page, setPage] = useState(0);
  const [p, setP] = PUC();

  const numPages = Math.ceil(items.length / pageSize);

  const pageNum = (page >= numPages || page < 0) ? 0 : page;

  const pageData = items.slice(pageNum * pageSize, pageNum * pageSize + pageSize);

  const dispatch = useDispatch();

  const selId = useSelector((state) => state.editId);

  const CBO = (id) => {
      dispatch(delNote(id));
      setP({...p, option: 0, open: true, message:'deleted!'});
  };

  const CBX = () => {};

  const deleteNote = (id) => {
    const msg = 'Wanna delete this note?';
    setP({...p, option: 1, open: true, message:msg, CBO:()=>CBO(id), CBX:CBX});
  };

  const editNote = (id) => {
    dispatch(setEditId(id));
    setP({...p, option: 0, open: true, message:'to the editor!'});
  };
  
  const toggleExpanded = (item) => {
     let tmp = {...item};
     tmp.isExpanded = ! tmp.isExpanded;
     dispatch(addNote(tmp));
  };

  return (
    <div className={"note-container"}>
      {items.length > 0 ? (
        <>
         <
            Pages 
            numPages={numPages} 
            page={pageNum} 
            setPage={setPage} 
          />
          
        <ul> {
        pageData.map((item) => (
          <li key={item.id} style={{width:'100%', margin:'auto', flex:'row'}}>
            <div
              className={"note-item"}
              style={{ backgroundColor: noteColor[item.bgColor] }}             
            >
              {(item.isExpanded || item.note.length <= noteLineLen) ?  item.note : item.note.substring(0, noteLineLen) + '...'}
              <span style={{display:'flex', justifyContent:'center', float:'right'}}>     
              <button  className={'btn-del'} onClick={()=>editNote(item.id)} disabled={selId===item.id} >
                <T r={selId===item.id ? <MdEditOff /> : <FiEdit />} s={selId===item.id ? 'edit in progress' : 'edit'} />
              </button>
              <button  className={'btn-del'}  onClick={()=>deleteNote(item.id)} disabled={selId===item.id}>
                <T r={selId===item.id ? <RiDeleteBin2Fill /> : <MdOutlineDeleteOutline />} s={selId===item.id ? 'delete disabled' : 'delete'} />
              </button>  
              {  
                (item.note.length > noteLineLen)   &&
              <button  className={'btn-del'} onClick={()=>toggleExpanded(item)} >
                <T r={item.isExpanded ? <LuShrink /> : <IoExpand />} s={item.isExpanded ? 'shrink' : 'expand'} />
              </button>
              }
              </span>
            </div> 
          </li>
          ))}
        </ul> 
         
         
          </>
        )
        : focus && <div className={"note-not-found"}>Empty list</div>}
    </div>
  );
};
export default NoteItem;
