import React, { useState } from "react";
import Search from "./Search";
import { IoIosInformationCircleOutline } from "react-icons/io";
import Modal from './Modal';
import {T, PUC} from '../PopUpJK';
import { CiDatabase } from "react-icons/ci";
import { useDispatch } from "react-redux";
import TestData from './TestData';
import { setTestData } from "../redux/notes/notesSlice";

var DataIn = false; 
const Header = () => {
    const [modal, setModal] = useState(false);
    const [, setP] = PUC();

    const dispatch = useDispatch();

    const delNo= () => {};

    const delYes = () => {
      DataIn = false;
      dispatch(setTestData([]));
      setP({option: 0, open:true, message:'All notes deleted'});
    };
  
    const handleDel = () => {
      const msg = 'Wanna delete all these notes?';
      setP({option: 1, open:true, message:msg, CBO:delYes, CBX: delNo});
    }
  
  const setData = () => {
    if (DataIn) {
        handleDel();
    } else {
        DataIn = true;
        dispatch(setTestData(TestData));
    }
  };

    return (
        <div >
            <div className={"header-text"}>Notes (React-Redux)</div>
            <div style={{display:'flex', justifyContent:'center'}}>
            <Search />
            &nbsp;
            <button className='modal-button'  onClick={()=>{setModal(true);}}><T r={<IoIosInformationCircleOutline />} s='About' /></button>
            &nbsp;
            <button className='modal-button' onClick={setData}><T r={<CiDatabase />} s={DataIn ? 'Remove test data' : 'Get test data'} w={80} /></button>
            </div>
            <Modal modal={modal} setModal={setModal} />
        </div>
    )
}
export default Header;
