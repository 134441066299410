import { nanoid } from "nanoid";


const TestData = [
    { id: nanoid(), isExpanded:false, bgColor:'red', note: 'A personal note',},
    { id: nanoid(), isExpanded:false, bgColor:'orange', note: 'Note that the value entered for this field may be publicly available occasionally. Therefore refrain from adding any sensitive information to this field.',},
    { id: nanoid(), isExpanded:false, bgColor:'yellow', note: 'This is one of React applications',},
    { id: nanoid(), isExpanded:false, bgColor:'green', note: 'This field needs some more substantial comment.',},
    { id: nanoid(), isExpanded:false, bgColor:'indigo', note: 'React Developer Tools are debugging tools for the open-source React library. It allows you to inspect the React component hierarchies in Chrome Developer Tools. Because Finsemble UI components are built using React, the React DevTools can be very helpful while debugging Finsemble.',},
    { id: nanoid(), isExpanded:false, bgColor:'violet', note: 'Make some more notes for your tests',},
];
export default TestData;
//length