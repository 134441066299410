import React from "react";
import {T} from '../PopUpJK';

const ColorButton = ({ bgColor, setBgColor }) => {
  const colors = ["red", 'orange', 'yellow',"green", "blue", 'indigo', 'violet'];

  const handleClick = (e) => {
    setBgColor(e.target.value);
  };
  return (
    <div>
      {colors.map((color, index) => (
        <
          T 
          r={
            <button
              onClick={handleClick}
              value={color}
              className={`btn btn-${color} ${
              color === bgColor ? "btn-active" : "null"
              } `}
              key={index}
            />
          }
          s={`color of note bg: ${color}`}
          w={150}
          key={index}
        />
      ))}
    </div>
  );
};
export default ColorButton;
