import {createSlice} from "@reduxjs/toolkit";

export const notesSlice = createSlice({
    name: 'notes',
    initialState: {
        items: [],
        searchText: "",
        editId: '',
    },
    reducers: {
        addNote: (state, action) => {
          function compare(a, b) {
            return a.note < b.note ? -1 : (a.note > b.note ? 1 : 0);
          }
          let tmpItems = state.items.filter(i => i.id !== action.payload.id);
          tmpItems.push(action.payload);
          tmpItems.sort(compare);
          state.items = tmpItems; 
        },
        delNote: (state, action) => {
            let lessItems = state.items.filter(i => i.id !== action.payload);
            state.items = lessItems;
        },
        setTestData: (state, action) => {
          function compare(a, b) {
            return a.note < b.note ? -1 : (a.note > b.note ? 1 : 0);
          }
          let tmpItems = [...action.payload];
          tmpItems.sort(compare);
          state.items = tmpItems;
        },
        setEditId: (state, action) => {
          state.editId = action.payload;
        },
        setSearchText: (state, action) => {
          state.searchText = action.payload;
        },
    },
});

export const {addNote, delNote, setEditId, setSearchText, setTestData} = notesSlice.actions
export default notesSlice.reducer