import "./App.css";
import Header from "./components/Header";
import Content from "./components/Content";
import NoteInput from "./components/NoteInput";
import { useSelector } from "react-redux";
import { nanoid } from "nanoid";
//word-wrap, line-break,pre,
function App() {

  const itemsArray = useSelector(state => state.items);
  const editIdId = useSelector(state => state.editId);
  const selItemArray = itemsArray.filter(item => item.id === editIdId);
  const selItem = selItemArray.length > 0 ? selItemArray[0] : 
    { id: nanoid(), note: '', isExpanded:false, bgColor:'red' };

  return (
    <div className={"container"}>
      <Header />
      <
        NoteInput  
        key={selItem.id} 
        selItem={ selItem} 
        edit={selItem.note !== ''}
      />
      <Content />
    </div>
  );
}

export default App;
