import React from "react";
import ColorButton from "./ColorButton";
import { useDispatch} from "react-redux";
import { addNote, setEditId } from "../redux/notes/notesSlice";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdOutlineSaveAlt } from "react-icons/md";
import {T} from '../PopUpJK';
import {PUC} from '../PopUpJK';

const NoteInput = ( {selItem, edit}) => {
  const [text, setText] = React.useState(selItem.note);
  const [bgColor, setBgColor] = React.useState(selItem.bgColor);
  const [p, setP] = PUC();

  const dispatch = useDispatch();

  //{option: 0, open:false, message:'', CBO:null, CBX: null, CB:null}
  const CBO = () => {
    if (text === "") {
      setP({...p, option: 0, open:true, message:'Please fill in the text!'});
    } else {
      dispatch(addNote({ id: selItem.id, note: text, isExpanded:selItem.isExpanded, bgColor }));
      dispatch(setEditId(''));
      setText(""); 
      setP({...p, option: 0, open:true, message:'saved!'});
    }
  };

  const CBX = () => {
    dispatch(setEditId(''));
    setText(""); 
    setP({...p, option: 0, open:true, message:'draft discarded!'});
  };

  const addNewNote = () => {
    setP({...p, option: 1, open:true, message:'Wanna save this note?', CBO:CBO, CBX:CBX});
  };

  return (
    <div className={"textarea-box"}>
      <textarea
        className={"textarea-input"}
        placeholder={"Enter your note here..."}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <div className={"textarea-inner-container"}>
        <ColorButton bgColor={bgColor} setBgColor={setBgColor} />
        <button className={"editSavebtn"} onClick={addNewNote}>
          {
            edit ? 
            <T r={<MdOutlineSaveAlt />} s={'Save'}/> :
            <T r={<IoIosAddCircleOutline />} s={'Add'}/> 
          }
        </button>
      </div>
    </div>
  );
};

export default NoteInput;
